import { Dictionary } from 'environment';
import {
	buildTemporaryTemplate,
	computeApiTemplates,
	getTemplateVariablesAndGroupsMap,
	prepareTemplateForApi
} from 'helpers/templates';
import { getUsersByIds } from 'store/data/accounts';
import { hydrateData } from 'store/data/variables';
import { doAfterTransaction } from 'store/ui/activities';
import { createActivity } from 'store/ui/activities';
import { ActionPayload, Thunk } from 'store/types';

import {
	ActionTypes,
	AddGroupToTemplateAction,
	AddVariableToTemplateAction,
	BuildNewBlankTemplateAction,
	CreateTemplateFromAllAction,
	DeleteTemplateAction,
	GetProjectTemplatesAction,
	GetPublicTemplatesAction,
	GetTemplatesAction,
	GetUserTemplatesAction,
	PublishTemplateAction,
	RemoveFromTemplateAction,
	RenameTemplateAction,
	SetPageViewAction,
	SetTemplateIdAction,
	SetSearchTermAction,
	UpdateTemplateAction,
	SortInsideTemplateAction,
	SetTemplateDescriptionAction,
	GetTemplateListOfSharedWithAction,
	ShareTemplateAction,
	ResetPublishedUnsupportedAction,
	GetTemplateAction,
	ResetListOfSharedWithAction,
	UnshareWithInstanceAction,
	ShareWithInstanceAction,
	CreateAndImportTemplateAction,
	SetCreateSourceTemplateIdAction
} from './types';
import { track } from 'app/tracking/TrackingProvider';

const getTemplatesAction = (payload: ActionPayload<GetTemplatesAction>): GetTemplatesAction => ({
	type: ActionTypes.GET_TEMPLATES,
	payload
});

export const getTemplates = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_TEMPLATES, dispatch });

	try {
		activity.begin();

		const {
			data: {
				projects: { projectId }
			}
		} = getState();

		if (projectId) {
			const apiTemplates = await context.api.data.templates().getTemplates(Number(projectId));

			const templates = computeApiTemplates(apiTemplates);

			dispatch(getTemplatesAction({ templates }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

export const getTemplateAction = (
	payload: ActionPayload<GetTemplateAction>
): GetTemplateAction => ({
	type: ActionTypes.GET_TEMPLATE,
	payload
});

const getProjectTemplatesAction = (
	payload: ActionPayload<GetProjectTemplatesAction>
): GetProjectTemplatesAction => ({
	type: ActionTypes.GET_PROJECT_TEMPLATES,
	payload
});

export const getProjectTemplates = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_PROJECT_TEMPLATES, dispatch });

	try {
		activity.begin();

		const {
			data: {
				projects: { projectId }
			}
		} = getState();

		if (projectId) {
			const apiTemplates = await context.api.data
				.templates()
				.getTemplatesFromProject(Number(projectId));

			const templates = computeApiTemplates(apiTemplates);

			dispatch(getProjectTemplatesAction({ templates }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const getUserTemplatesAction = (
	payload: ActionPayload<GetUserTemplatesAction>
): GetUserTemplatesAction => ({
	type: ActionTypes.GET_USER_TEMPLATES,
	payload
});

export const getUserTemplates = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_USER_TEMPLATES, dispatch });

	try {
		activity.begin();

		const {
			auth: { username },
			data: {
				projects: { projectId }
			}
		} = getState();

		if (projectId && username) {
			const apiTemplates = await context.api.data.templates().getTemplatesFromUser();

			const templates = computeApiTemplates(apiTemplates);

			dispatch(getUserTemplatesAction({ templates, currentUserId: username }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const getPublicTemplatesAction = (
	payload: ActionPayload<GetPublicTemplatesAction>
): GetPublicTemplatesAction => ({
	type: ActionTypes.GET_PUBLIC_TEMPLATES,
	payload
});

export const getPublicTemplates = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({ type: ActionTypes.GET_PUBLIC_TEMPLATES, dispatch });

	try {
		activity.begin();

		const {
			data: {
				projects: { projectId }
			}
		} = getState();

		if (projectId) {
			const apiTemplates = await context.api.data.templates().getPublicTemplates();

			const templates = computeApiTemplates(apiTemplates);

			dispatch(getPublicTemplatesAction({ templates }));
		}
	} catch (e: any) {
		activity.error({ error: e.message });
	} finally {
		activity.end();
	}
};

const publishTemplateAction = (
	payload: ActionPayload<PublishTemplateAction>
): PublishTemplateAction => ({
	type: ActionTypes.PUBLISH_TEMPLATE,
	payload
});

export const publishTemplate =
	(templateId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.PUBLISH_TEMPLATE, dispatch });

		try {
			activity.begin();

			const {
				auth: { username },
				data: {
					projects: { projectId },
					templates: { templatesById }
				}
			} = getState();

			const templateToSave = templatesById[templateId];
			if (projectId && username && templateToSave) {
				const { apiTemplate, avoidUnnecessaryApiCall, calculatedVariableNames } =
					prepareTemplateForApi(templateToSave, username);

				let newTemplateId = undefined;
				if (projectId && !avoidUnnecessaryApiCall) {
					newTemplateId = await context.api.data
						.templates()
						.publishTemplate(Number(projectId), apiTemplate);
				}

				if (avoidUnnecessaryApiCall) {
					dispatch(
						resetPublishedUnsupported({
							variablesToDelete: calculatedVariableNames
						})
					);
				}

				if (newTemplateId && apiTemplate.templateId && !avoidUnnecessaryApiCall) {
					dispatch(
						publishTemplateAction({
							templateId: newTemplateId,
							removedTempId: apiTemplate.templateId
						})
					);
				}

				track({
					eventName: 'project_template_published',
					data: {
						variableCount: templateToSave.variables.length,
						groupCount: templateToSave.groups.length
					}
				});
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const updateTemplateAction = (
	payload: ActionPayload<UpdateTemplateAction>
): UpdateTemplateAction => ({
	type: ActionTypes.UPDATE_TEMPLATE,
	payload
});

export const updateTemplate =
	(payload: ActionPayload<UpdateTemplateAction> & { allowTimeDuration: boolean }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.UPDATE_TEMPLATE, dispatch });

		try {
			activity.begin();

			const { projectId, templatesById, editableTemplatesIds } = getState().data.templates;
			const { username } = getState().auth;

			const { templateId, shareTemplateGlobally, allowTimeDuration } = payload;

			const template = templatesById[templateId];

			if (projectId && templateId && editableTemplatesIds.includes(templateId) && username) {
				const { apiTemplate, calculatedVariableNames, avoidUnnecessaryApiCall } =
					prepareTemplateForApi(
						template,
						username,
						allowTimeDuration,
						shareTemplateGlobally
					);

				let status = false;

				if (!avoidUnnecessaryApiCall) {
					status = await context.api.data
						.templates()
						.updateTemplate(Number(projectId), apiTemplate);
				}

				if (avoidUnnecessaryApiCall) {
					dispatch(
						resetPublishedUnsupported({
							variablesToDelete: calculatedVariableNames,
							avoidUnnecessaryApiCall
						})
					);
				}

				track({
					eventName: 'project_template_updated',
					data: {
						variableCount: template.variables.length,
						groupCount: template.groups.length
					}
				});

				if (status) dispatch(updateTemplateAction({ templateId, shareTemplateGlobally }));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const deleteTemplateAction = (
	payload: ActionPayload<DeleteTemplateAction>
): DeleteTemplateAction => ({
	type: ActionTypes.DELETE_TEMPLATE,
	payload
});

export const deleteTemplate =
	(templateId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.DELETE_TEMPLATE, dispatch });

		try {
			activity.begin();

			const { projectId } = getState().data.templates;

			const isLocalTemplate = templateId.toString().includes('temporaryId-');

			if (projectId && !isLocalTemplate) {
				await context.api.data
					.templates()
					.deleteTemplate(Number(projectId), Number(templateId));
			}

			dispatch(deleteTemplateAction({ templateId }));
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const getTemplateListOfSharedWithAction = (
	payload: ActionPayload<GetTemplateListOfSharedWithAction>
): GetTemplateListOfSharedWithAction => ({
	type: ActionTypes.GET_TEMPLATE_LIST_OF_SHARED_WITH,
	payload
});

export const getTemplateListOfSharedWith = (): Thunk => async (dispatch, getState, context) => {
	const activity = createActivity({
		type: ActionTypes.GET_TEMPLATE_LIST_OF_SHARED_WITH,
		dispatch
	});

	try {
		activity.begin();

		const { projectId, templateId } = getState().data.templates;
		const {
			users: { byEmail, byUserId }
		} = getState().data.accounts;
		const { username } = getState().auth;

		if (projectId && templateId) {
			const { users, projects, accessPublicRead } = await context.api.data
				.templates()
				.getSharedWith(Number(projectId), Number(templateId));

			const usersWithCompleteInfo: string[] = [];
			const missingUsersInfo: string[] = [];
			if (users.length) {
				Object.values(byEmail).forEach(userByEmail => {
					if (userByEmail.userid) {
						usersWithCompleteInfo.push(userByEmail.userid);
					}
				});
				Object.values(byUserId).forEach(userById => {
					if (userById.userid) {
						usersWithCompleteInfo.push(userById.userid);
					}
				});
				users.forEach(user => {
					if (!usersWithCompleteInfo.includes(user.userId) && username !== user.userId) {
						missingUsersInfo.push(user.userId);
					}
				});

				// Get users with missing info by id
				if (missingUsersInfo.length) {
					dispatch(getUsersByIds(missingUsersInfo));
				}
			}

			dispatch(
				getTemplateListOfSharedWithAction({
					users,
					projects,
					accessPublicRead,
					templateId
				})
			);
		}
	} catch (e: any) {
		activity.error({ error: Dictionary.errors.api.templates.couldNotGetListOfSharedWith });
	} finally {
		activity.end();
	}
};

export const shareTemplate =
	(payload: ActionPayload<ShareTemplateAction>): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.SHARE_TEMPLATE, dispatch });

		try {
			activity.begin();

			const { projectId, templateId } = getState().data.templates;

			if (projectId && templateId) {
				const { users, projects } = payload;

				await context.api.data
					.templates()
					.shareTemplate(Number(projectId), Number(templateId), users, projects);

				const action: ShareTemplateAction = {
					type: ActionTypes.SHARE_TEMPLATE,
					payload
				};

				dispatch(dispatch(action));
			}
		} catch (e: any) {
			activity.error({ error: Dictionary.errors.api.templates.couldNotShareTemplate });
		} finally {
			activity.end();
		}
	};

export const importTemplate =
	(templateId: string): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({ type: ActionTypes.IMPORT_TEMPLATE, dispatch });

		try {
			activity.begin();

			const {
				templates: { projectId }
			} = getState().data;

			if (projectId) {
				const { transactionId } = await context.api.data.templates().importTemplate({
					projectId: Number(projectId),
					templateId: Number(templateId)
				});

				if (transactionId) {
					dispatch(
						doAfterTransaction({
							transactionId,
							actionType: ActionTypes.IMPORT_TEMPLATE,
							callback: () => dispatch(hydrateData())
						})
					);
				} else {
					dispatch(hydrateData());
				}
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

const createAndImportAction = (
	payload: ActionPayload<CreateAndImportTemplateAction>
): CreateAndImportTemplateAction => ({
	type: ActionTypes.CREATE_AND_IMPORT_TEMPLATE,
	payload
});

export const createAndImport =
	(checkedState: { variables: string[]; groups: string[] }): Thunk =>
	async (dispatch, getState, context) => {
		const activity = createActivity({
			type: ActionTypes.CREATE_AND_IMPORT_TEMPLATE,
			dispatch
		});

		try {
			activity.begin();

			const {
				auth: { username },
				data: {
					projects: { projectId },
					templates: { sourceCreateTemplateId, templatesById }
				}
			} = getState();

			if (projectId && username && sourceCreateTemplateId) {
				// COMPUTE ON TEMPLATEID FROM REDUX
				const { variables, groups } = templatesById[sourceCreateTemplateId];

				// COMPUTES MAP OF VARIABLES AND GROUPS
				const { variables: variablesMap, groups: groupsMap } =
					getTemplateVariablesAndGroupsMap(variables, groups);

				const importPayload = {
					variables: checkedState.variables.map(variable => variablesMap[variable]),
					groups: checkedState.groups.map(group => groupsMap[group])
				};

				const template = buildTemporaryTemplate({ ...importPayload, owner: username });

				const { apiTemplate, avoidUnnecessaryApiCall, calculatedVariableNames } =
					prepareTemplateForApi(template, username);

				let newTemplateId = '';
				if (projectId && !avoidUnnecessaryApiCall) {
					newTemplateId = await context.api.data
						.templates()
						// < --- we need data here that is not being populated by previous dispatch!!!! HOW
						.publishTemplate(Number(projectId), apiTemplate);
				}

				if (avoidUnnecessaryApiCall) {
					dispatch(
						resetPublishedUnsupported({
							variablesToDelete: calculatedVariableNames
						})
					);
				}

				//IMPORT
				if (newTemplateId) {
					const { transactionId } = await context.api.data.templates().importTemplate({
						projectId: Number(projectId),
						templateId: Number(newTemplateId)
					});

					if (transactionId) {
						await dispatch(
							doAfterTransaction({
								transactionId,
								actionType: ActionTypes.IMPORT_TEMPLATE,
								callback: () => {
									dispatch(
										createAndImportAction({
											template,
											removedTemplateId: template.templateId,
											newTemplateId: newTemplateId
										})
									);
									dispatch(setSoruceCreateTemplateId({ templateId: null }));
								}
							})
						);
					} else {
						dispatch(
							createAndImportAction({
								template,
								removedTemplateId: template.templateId,
								newTemplateId: newTemplateId
							})
						);
						dispatch(setSoruceCreateTemplateId({ templateId: null }));
					}
				}

				//DELETE PUBLISHED;
				await context.api.data
					.templates()
					.deleteTemplate(Number(projectId), Number(newTemplateId));
			}
		} catch (e: any) {
			activity.error({ error: e.message });
		} finally {
			activity.end();
		}
	};

export const setTemplateId = (
	payload: ActionPayload<SetTemplateIdAction>
): SetTemplateIdAction => ({
	type: ActionTypes.SET_TEMPLATE_ID,
	payload
});

export const setSoruceCreateTemplateId = (
	payload: ActionPayload<SetCreateSourceTemplateIdAction>
): SetCreateSourceTemplateIdAction => ({
	type: ActionTypes.SET_SOURCE_CREATE_TEMPLATE_ID,
	payload
});

export const setPageView = (payload: ActionPayload<SetPageViewAction>): SetPageViewAction => ({
	type: ActionTypes.SET_PAGE_VIEW,
	payload
});

export const resetPublishedUnsupported = (
	payload: ActionPayload<ResetPublishedUnsupportedAction>
): ResetPublishedUnsupportedAction => ({
	type: ActionTypes.RESET_PUBLISHED_UNSUPPORTED,
	payload
});

export const setTemplateSearchTerm = (
	payload: ActionPayload<SetSearchTermAction>
): SetSearchTermAction => ({
	type: ActionTypes.SET_SEARCH_TERM,
	payload
});

export const buildNewBlankTemplate = (
	payload: ActionPayload<BuildNewBlankTemplateAction>
): BuildNewBlankTemplateAction => ({
	type: ActionTypes.BUILD_NEW_BLANK_TEMPLATE,
	payload
});

export const renameTemplate = (
	payload: ActionPayload<RenameTemplateAction>
): RenameTemplateAction => ({
	type: ActionTypes.RENAME_TEMPLATE,
	payload
});

export const resetListsOfSharedWith = (
	payload: ActionPayload<ResetListOfSharedWithAction>
): ResetListOfSharedWithAction => ({
	type: ActionTypes.RESET_LISTS_OF_SHARED_WITH,
	payload
});

export const setTemplateDescription = (
	payload: ActionPayload<SetTemplateDescriptionAction>
): SetTemplateDescriptionAction => ({
	type: ActionTypes.SET_TEMPLATE_DESCRIPTION,
	payload
});

export const addVariableToTemplate = (
	payload: ActionPayload<AddVariableToTemplateAction>
): AddVariableToTemplateAction => ({
	type: ActionTypes.ADD_VARIABLE_TO_TEMPLATE,
	payload
});

export const addGroupToTemplate = (
	payload: ActionPayload<AddGroupToTemplateAction>
): AddGroupToTemplateAction => ({
	type: ActionTypes.ADD_GROUP_TO_TEMPLATE,
	payload
});

export const removeFromTemplate = (
	payload: ActionPayload<RemoveFromTemplateAction>
): RemoveFromTemplateAction => ({
	type: ActionTypes.REMOVE_FROM_TEMPLATE,
	payload
});

export const createTemplateFromAll = (
	payload: ActionPayload<CreateTemplateFromAllAction>
): CreateTemplateFromAllAction => ({
	type: ActionTypes.CREATE_TEMPLATE_FROM_ALL,
	payload
});

export const sortInsideTemplate = (
	payload: ActionPayload<SortInsideTemplateAction>
): SortInsideTemplateAction => ({
	type: ActionTypes.SORT_INSIDE_TEMPLATE,
	payload
});

export const shareWithInstance = (
	payload: ActionPayload<ShareWithInstanceAction>
): ShareWithInstanceAction => ({
	type: ActionTypes.SHARE_WITH_INSTANCE,
	payload
});

export const unshareWithInstance = (
	payload: ActionPayload<UnshareWithInstanceAction>
): UnshareWithInstanceAction => ({
	type: ActionTypes.UNSHARE_WITH_INSTANCE,
	payload
});
