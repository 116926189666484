import { useEffect, useState } from 'react';
import {
	ImportDropzone,
	Title,
	Subtitle,
	CardContainer,
	Card,
	CardLabel,
	ImportText,
	NarrowContainer,
	SyncedWidthCard
} from 'components/Projects/CreateAndImport';
import { StickyFooter } from 'components/UI/StickyFooter';
import { Icon } from 'components/UI/Icons';
import { Svgs, Colors } from 'environment';
import { ImportType, SetState } from 'types/index';
import { Typography } from 'components/UI/Typography';
import { useNavigation } from 'hooks/navigation';
import { useTranslation, useProjectId, useImportDataText } from 'hooks/store';
import { Spacer } from 'components/UI/Spacer';
import { FileImportErrors } from 'types/data/projects/types';
import { useResetImportPercentage } from 'hooks/store/data/projects/import/useResetImportPercentage';
import { ImportErrorWarning } from '../ImportErrorWarning/ImportErrorWarning';
import { useTracking } from 'app/tracking/TrackingProvider';

interface Props {
	importFileName: string;
	selectedOption: ImportType | null;
	uploadingFile: boolean;
	fileImportErrors: FileImportErrors | null;
	setFileImportErrors: SetState<FileImportErrors | null>;
	setImportFileName: SetState<string>;
	setSelectedOption: SetState<ImportType | null>;
	handleFinishedLoading: () => void;
	handleUploadingError: () => void;
	handleReset: () => void;
	onTemplateNext: () => void;
}

export function ProjectImportOptions({
	importFileName,
	selectedOption,
	uploadingFile,
	fileImportErrors,
	setFileImportErrors,
	setImportFileName,
	setSelectedOption,
	handleFinishedLoading,
	handleUploadingError,
	handleReset,
	onTemplateNext
}: Props) {
	const { translate } = useTranslation();
	const { routes, navigate, goBack } = useNavigation();

	const { track } = useTracking();

	const [projectId] = useProjectId();
	const [{ data: importText }] = useImportDataText();

	const [dropzoneVisible, setDropzoneVisible] = useState(false);
	const [shouldReset, setShouldReset] = useState(false);

	const resetImportPercentage = useResetImportPercentage();

	useEffect(() => {
		if (!fileImportErrors) return;

		if (importFileName !== '' && selectedOption && !uploadingFile && shouldReset) {
			handleReset();
		}
	}, [importFileName, selectedOption, uploadingFile, fileImportErrors, shouldReset]);

	const IMPORT_OPTIONS = [
		{
			icon: Svgs.FileText,
			label: translate(
				({ projects }) => projects.createAndImport.importOptions.manuallyDefine
			),
			value: ImportType.Manual
		},
		{
			icon: Svgs.FilePlus,
			label: translate(({ projects }) => projects.createAndImport.importOptions.importNow),
			value: ImportType.Now
		},
		{
			icon: Svgs.Clock,
			label: translate(({ projects }) => projects.createAndImport.importOptions.defineLater),
			value: ImportType.Later
		}
	];

	function handlePrimaryClick() {
		if (selectedOption === ImportType.Now) {
			setDropzoneVisible(true);
		}
		if (selectedOption === ImportType.Later) {
			navigate(routes.projects.list);
		}
		if (selectedOption === ImportType.Manual && projectId) {
			navigate(routes.projects.variables.view(projectId));
		}
		if (selectedOption === ImportType.VariableTemplate) {
			onTemplateNext();
		}

		track({ eventName: 'project_import_option_selected', data: { selectedOption } });
	}

	function handleNeutralClick() {
		if (dropzoneVisible) {
			setDropzoneVisible(false);
		} else {
			goBack();
		}
	}

	function isCardDisalbed(value: ImportType) {
		return dropzoneVisible && selectedOption !== value;
	}

	function handleApiFileImportErrors(errors: FileImportErrors) {
		resetImportPercentage();
		setShouldReset(true);
		setFileImportErrors(errors);
	}

	return (
		<>
			<NarrowContainer>
				<Title>{translate(({ projects }) => projects.createAndImport.title.create)}</Title>

				<Subtitle>
					{translate(({ projects }) =>
						uploadingFile
							? projects.createAndImport.subtitle.loadingFile
							: projects.createAndImport.subtitle.create
					)}
				</Subtitle>

				<CardContainer>
					{IMPORT_OPTIONS.slice(0, 3).map(({ icon, label, value }) => (
						<Card
							data-test-id={value}
							data-testid={value}
							key={value}
							title={label}
							selected={selectedOption === value}
							onClick={() => !isCardDisalbed(value) && setSelectedOption(value)}
							disabled={isCardDisalbed(value)}
						>
							<Icon
								svg={icon}
								customSize={4}
								marginOffset={{ top: 3.9, bottom: 2.3 }}
								colors={{
									color: isCardDisalbed(value)
										? Colors.text.disabled
										: selectedOption === value
										? Colors.primary.normal
										: Colors.text.main
								}}
								propagate
							/>
							<CardLabel>{label}</CardLabel>
						</Card>
					))}
				</CardContainer>

				{/** CREATE WITH TEMPLATE */}
				<Subtitle>
					{translate(dict => dict.projects.createAndImport.template.modal.cardTitle)}
				</Subtitle>

				<SyncedWidthCard
					itemCount={IMPORT_OPTIONS.length}
					data-test-id={ImportType.VariableTemplate}
					key={ImportType.VariableTemplate}
					title={translate(
						({ projects }) => projects.createAndImport.importOptions.defineLater
					)}
					selected={selectedOption === ImportType.VariableTemplate}
					onClick={() =>
						!isCardDisalbed(ImportType.VariableTemplate) &&
						setSelectedOption(ImportType.VariableTemplate)
					}
					disabled={isCardDisalbed(ImportType.VariableTemplate)}
				>
					<Icon
						svg={Svgs.Globe}
						customSize={4}
						marginOffset={{ top: 3.9, bottom: 2.3 }}
						colors={{
							color: isCardDisalbed(ImportType.VariableTemplate)
								? Colors.text.disabled
								: selectedOption === ImportType.VariableTemplate
								? Colors.primary.normal
								: Colors.text.main
						}}
						propagate
					/>
					<CardLabel>
						{translate(
							({ projects }) => projects.createAndImport.template.modal.cardTitle
						)}
					</CardLabel>
				</SyncedWidthCard>

				<Spacer size={s => s.m}></Spacer>

				{dropzoneVisible && (
					<>
						<ImportText>{importText?.content}</ImportText>
						<ImportDropzone
							importType={ImportType.Now}
							setImportFileName={setImportFileName}
							importFileName={importFileName}
							handleFinishedLoading={handleFinishedLoading}
							handleUploadingError={handleUploadingError}
							resetFileImportErrors={() => {
								!!fileImportErrors && setFileImportErrors(null);
							}}
							setShouldReset={() => {
								shouldReset && setShouldReset(false);
							}}
							handleApiFileImportErrors={handleApiFileImportErrors}
						/>
					</>
				)}

				{fileImportErrors && (
					<>
						<Spacer size={s => s.s} />
						<ImportErrorWarning
							errors={fileImportErrors}
							onClose={setFileImportErrors}
							importFileName={importFileName}
						/>
					</>
				)}
				{uploadingFile && (
					<Typography.Hint marginOffset={{ y: 1 }}>
						{translate(dict => dict.projects.createAndImport.dropzoneLabel)}
					</Typography.Hint>
				)}
			</NarrowContainer>

			<StickyFooter
				primary={
					!dropzoneVisible && {
						label: translate(({ buttons }) => buttons.continue),
						disabled: !selectedOption,
						onClick: handlePrimaryClick
					}
				}
				neutral={
					!uploadingFile && {
						label: translate(({ buttons }) =>
							dropzoneVisible ? buttons.back : buttons.cancel
						),
						onClick: handleNeutralClick
					}
				}
				maxWidth={65.2}
			/>
		</>
	);
}
