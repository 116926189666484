import { sendRequest, TEMPLATES_URL } from 'api/utils';
import {
	DeleteTemplateResponse,
	ApiTemplate,
	GetTemplatesResponse,
	GetTemplateResponse,
	PublishTemplateResponse,
	GetSharedWithResponse,
	ImportTemplateInput,
	ImportTemplateRequest,
	ImportTemplateResponse,
	ImportTemplateOutput
} from './types';

import { Dictionary } from 'environment';
import { ProjectAccessInterface, UserAccessInterface } from 'store/data/templates';
import { GenericApiResponse } from 'api/types';

const methods = {
	// GET TEMPLATES
	getTemplate: 'getTemplate',
	getTemplates: 'getTemplates',
	getTemplatesFromProject: 'getTemplatesFromProject',
	getTemplatesFromUser: 'getTemplatesFromUser',
	getPublicTemplates: 'getPublicTemplates',
	getSharedWith: 'getSharedWith',

	// OTHER TEMPLATES METHODS
	publishTemplate: 'publishTemplate',
	updateTemplate: 'editTemplate',
	deleteTemplate: 'deleteTemplate',
	shareTemplate: 'shareTemplate',
	importTemplate: 'importTemplate'
};

export default () => ({
	async getTemplate(projectId: number, templateId: number): Promise<ApiTemplate> {
		const { data }: GetTemplateResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.getTemplate,
			projectId,
			templateId
		});

		if (!data.template) {
			throw new Error(Dictionary.errors.api.templates.couldNotGetTemplate);
		}

		return data.template;
	},

	async getTemplates(projectId: number): Promise<ApiTemplate[]> {
		const { data }: GetTemplatesResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.getTemplates,
			projectId
		});

		if (!data.templates) {
			throw new Error(Dictionary.errors.api.templates.couldNotGetTemplates);
		}

		return data.templates;
	},

	async getTemplatesFromProject(projectId: number): Promise<ApiTemplate[]> {
		const { data }: GetTemplatesResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.getTemplatesFromProject,
			projectId
		});

		if (!data.templates) {
			throw new Error(Dictionary.errors.api.templates.couldNotGetProjectTemplates);
		}

		return data.templates;
	},

	async getTemplatesFromUser(): Promise<ApiTemplate[]> {
		const { data }: GetTemplatesResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.getTemplatesFromUser
		});

		if (!data.templates) {
			throw new Error(Dictionary.errors.api.templates.couldNotGetUserTemplates);
		}

		return data.templates;
	},

	async getPublicTemplates(): Promise<ApiTemplate[]> {
		const { data }: GetTemplatesResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.getPublicTemplates
		});

		if (!data.templates) {
			throw new Error(Dictionary.errors.api.templates.couldNotGetPublicTemplates);
		}

		return data.templates;
	},

	async getSharedWith(projectId: number, templateId: number) {
		const { data }: GetSharedWithResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.getSharedWith,
			projectId,
			templateId
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.templates.couldNotGetListOfSharedWith);
		}

		const { users, projects, accessPublicRead } = data;

		return { users, projects, accessPublicRead };
	},

	async publishTemplate(projectId: number, template: ApiTemplate): Promise<string> {
		const { templateName, variablesJson, groupsJson, templateDescription, accessPublicRead } =
			template;
		const { data }: PublishTemplateResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.publishTemplate,
			projectId,
			accessPublicRead,
			isSingleVariableTemplate: false,
			templateName,
			groupsJson,
			variablesJson,
			templateDescription
		});

		if (!data.templateId) {
			throw new Error(Dictionary.errors.api.templates.couldNotPublishNewTemplate);
		}

		return data.templateId.toString();
	},

	async updateTemplate(projectId: number, template: ApiTemplate) {
		const {
			templateName,
			variablesJson,
			groupsJson,
			templateDescription,
			templateId,
			accessPublicRead
		} = template;

		const { data }: GenericApiResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.updateTemplate,
			projectId,
			templateId: Number(templateId),
			templateName,
			templateDescription,
			variablesJson,
			groupsJson,
			accessPublicRead
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.templates.couldNotUpdateTemplate);
		}

		return true;
	},

	async deleteTemplate(projectId: number, templateId: number) {
		const { data }: DeleteTemplateResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.deleteTemplate,
			projectId,
			templateId
		});

		if (!data) {
			throw new Error(Dictionary.errors.api.templates.couldNotDeleteTemplate);
		}

		return true;
	},

	async shareTemplate(
		projectId: number,
		templateId: number,
		users: UserAccessInterface[],
		projects: ProjectAccessInterface[]
	) {
		const { data }: GenericApiResponse = await sendRequest(TEMPLATES_URL, {
			method: methods.shareTemplate,
			projectId,
			templateId,
			users,
			projects
		});

		if (data.httpStatusCode !== 200) {
			throw new Error(Dictionary.errors.api.templates.couldNotShareTemplate);
		}

		return true;
	},

	async importTemplate(input: ImportTemplateInput): Promise<ImportTemplateOutput> {
		const { data } = await sendRequest<ImportTemplateRequest, ImportTemplateResponse>(
			TEMPLATES_URL,
			{
				method: methods.importTemplate,
				...input
			}
		);

		if (data.httpStatusCode !== 200) {
			throw new Error();
		}

		const output: ImportTemplateOutput = {
			transactionId: data.transactionId
		};

		return output;
	}
});
